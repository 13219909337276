<template>
    <Modal v-model="showForm" title="新增模版" :mask-closable="false">
        <Form ref="editForm" :model="editForm" :rules="editFormRule" label-colon :label-width="80">
            <FormItem label="模版名称" prop="name">
                <Input type="text" :maxlength="40" v-model="editForm.name" placeholder="请输入模版名称"/>
            </FormItem>
            <FormItem label="模板文件" prop="orignalName">
                <Upload ref="upload"
                        type="drag"
                        multiple
                        :max-size="50 * 1024"
                        :action="uploadFiles"
                        :format="['docx']"
                        accept=".docx"
                        :default-file-list="defaultFileList"
                        :data="{type: ''}"
                        :on-exceeded-size="handFileExceeded"
                        :on-remove="handleFileRemove"
                        :on-success="handleFileSuccess"
                        :disabled="editForm.currentName !== null && editForm.currentName !== ''"
                        :headers="header">
                    <div class="upload-btn-l">
                        <Icon type="md-cloud-upload"></Icon>
                        <p class="tips">要求：支持.docx格式, 5M以内</p>
                    </div>
                </Upload>
            </FormItem>
            <FormItem class="plain-item" label="模板封面" prop="orignalCoverName">
                <Upload ref="upload" type="drag"
                        :headers="header"
                        :max-size="5 * 1024"
                        :action="uploadFiles"
                        :data="{type: 'public'}"
                        :show-upload-list="false"
                        :format="['jpg', 'png', 'JPG', 'JPEG']"
                        accept=".jpg,.png,.JPG,.JPEG"
                        :on-exceeded-size="(file) => handExceeded(file, 5)"
                        :on-success="(res, file, fileList) => handleSuccess(res, file, fileList, 'photoImgArray')"
                        v-if="photoImgArray.length < 1">
                    <div class="upload-btn-l">
                        <Icon type="md-cloud-upload"></Icon>
                        <p class="tips">要求：支持.jpg，.png，.JPG，.JPEG格式，5M以内</p>
                    </div>
                </Upload>
                <div class="upload-image-list" v-for="(item, index) in photoImgArray">
                    <img :src="item.imgData"  style="width: 100%"/>
                    <div class="upload-image-list-cover">
                        <Icon type="ios-eye" @click.native="handlePreview(item.imgData)"></Icon>
                        <Icon type="ios-trash error" @click.native="handleRemove('photoImgArray', index)"></Icon>
                    </div>
                </div>
            </FormItem>
        </Form>
        <Modal title="查看封面" v-model="showPreview" footer-hide :width="860">
            <img :src="imgView" v-if="showPreview" style="width: 100%"/>
        </Modal>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit">确定</Button>
        </div>
    </Modal>
</template>
<script>
import {uploadFileURL, reqDeleteFiles} from "@/api/system-api";
import {downloadFile} from "../../../api/system-api";
import {reqEditResumeTemplate, reqGetResumeTemplateById} from "../../../api/admin-api";
import {SERVER_HOST} from "../../../common/portConfig";

export default {
    name: "addWcSlideshow",
    components: {},
    props: {
        value: {
            type: Boolean,
            default: false
        },
        id: '',
    },
    data() {
        return {
            header: {},
            showPreview: false,
            imgView: null,
            fileCounts: 0,
            uploadFiles: uploadFileURL,
            loading: false,
            showForm: this.value,
            defaultFileList: [],
            photoImgArray: [],
            editForm: {
                name: '',
                orignalName: null,
                currentName: null,
                orignalCoverName: null,
                currentCoverName: null,
            },
            editFormRule: {
                name: [{required: true, message: '请输入模版名称', trigger: 'blur'}],
                orignalName: [
                    {required: true, message: "请上传模版文件", trigger: 'blur'}
                ],
                orignalCoverName: [
                    {required: true, message: "请上传模版封面", trigger: 'blur'}
                ],
            },
        }
    },
    methods: {
        getResumeTemplateById() {
            reqGetResumeTemplateById({id: this.id}).then((res)=>{
                this.editForm = res.data.data
                this.photoImgArray.push({imgName: res.data.data.currentCoverName, imgData: SERVER_HOST + '/resource/public/' + res.data.data.currentCoverName});
                this.defaultFileList.push({name: res.data.data.orignalName})
            }).catch(()=>{
                this.editForm = {}
            })
        },
        handleSubmit() {
            this.loading = true;
            this.$refs['editForm'].validate((valid) => {
                if (!valid) {
                    this.loading = false;
                    return false;
                }
                reqEditResumeTemplate(this.editForm).then((res)=>{
                    this.loading = false;
                    if (res.data.code === 1){
                        this.$Message.success('编辑成功');
                        this.$emit('getResumeTemplate');
                        this.closeModal(false);
                    }
                }).catch(()=>{
                    this.loading = false;
                    this.$Message.error('编辑失败');
                })
            });
        },
        //上传文件大小
        handFileExceeded() {
            this.$Message.error('文件大小不得超过50M')
        },
        handleFileRemove(file, fileList) {
            this.editForm.orignalName = null;
            this.editForm.currentName = null;
        },
        handleFileSuccess(res, file, fileList) {
            if (res.code === 1) {
                this.editForm.orignalName = res.data.orignalName;
                this.editForm.currentName = res.data.currentName;
            } else {
                this.editForm.orignalName = null;
                this.editForm.currentName = null;
                this.$Message.error('上传失败');
            }
        },
        handleBeforeUpload(file, maxNum) {
            this.fileCounts++;
            if (this.fileCounts > maxNum) {
                this.$Message.error('最多只能上传' + maxNum + '个文件');
                return false;
            }else {
                this.editForm.fileArray.push(file)
            }
        },
        //上传文件大小
        handExceeded(file, maxSize) {
            this.$Message.error('文件大小不得超过' + maxSize + 'M');
        },
        //上传成功
        handleSuccess(res, file, fileList, propName) {
            this.fileCounts = 0;
            if (res.code === 1) {
                this.editForm.orignalCoverName = res.data.fileName;
                this.editForm.currentCoverName = res.data.currentName;
                this.photoImgArray.push({imgName: res.data.currentName, imgData: SERVER_HOST + '/resource/public/' + res.data.currentName})
                this.$Message.success('上传成功');
            } else {
                this.$Message.error('上传失败');
            }
        },
        handlePreview(imgData) {
            this.imgView = imgData;
            this.showPreview = true;
        },
        handleRemove(propName, index) {
            this[propName].splice(index, 1);
            this.editForm.currentCoverName = null;
            this.editForm.orignalCoverName = null;
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.$refs['editForm'].resetFields();
                this.photoImgArray = []
                this.defaultFileList = []
                this.getResumeTemplateById();
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    },
    mounted() {
        let token = sessionStorage.getItem('Authorization')
        this.header = {Authorization: token}
    }
}
</script>
