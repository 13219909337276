<template>
    <Modal v-model="showForm" title="查看模版" :mask-closable="false">
        <Form class="plain-form" ref="viewForm" :model="viewForm" :rules="viewFormRule" label-colon :label-width="80">
            <FormItem label="模版名称">{{ viewForm.name }}</FormItem>
            <FormItem label="模板文件">
                <a @click="handleDownload(viewForm.orignalName, viewForm.currentName)">{{ viewForm.orignalName }}</a>
            </FormItem>
            <FormItem label="模版封面">
                <div class="upload-image-list">
                    <img :src="imgData">
                    <div class="upload-image-list-cover">
                        <Icon type="ios-eye" @click.native="handlePreview(imgData)"></Icon>
                    </div>
                </div>
            </FormItem>
        </Form>
        <Modal title="查看图片" v-model="showPreview" footer-hide :width="860">
            <img :src="imgData" style="width: 100%" />
        </Modal>
        <div slot="footer">
            <Button type="primary" @click="closeModal(false)">确定</Button>
        </div>
    </Modal>
</template>
<script>
import {reqGetResumeTemplateById} from "../../../api/admin-api";
import {downloadFile} from "../../../api/system-api";
import {SERVER_HOST} from "../../../common/portConfig";

export default {
    name: "viewWcSlideshow",
    components: {},
    props: {
        value: {
            type: Boolean,
            default: false
        },
        id: '',
    },
    data() {
        return {
            showPreview: false,
            imgData: null,
            loading: false,
            showForm: this.value,
            deleteName: [],
            viewForm: {},
            viewFormRule: {},
        }
    },
    methods: {
        getResumeTemplateById() {
            reqGetResumeTemplateById({id: this.id}).then((res)=>{
                this.viewForm = res.data.data
                this.imgData = SERVER_HOST + '/resource/public/' + res.data.data.currentCoverName;
            }).catch(()=>{
                this.viewForm = {}
            })
        },
        handleDownload(originalFileName, fileName) {
            let para = {
                type: '',
                fileName: fileName
            }
            downloadFile(para).then((res) => {
                if (res.data.size > 0) {
                    let blob = new Blob([res.data])
                    let downloadElement = document.createElement('a')
                    let href = window.URL.createObjectURL(blob) //创建下载的链接
                    downloadElement.href = href
                    downloadElement.download = originalFileName //下载后文件名
                    document.body.appendChild(downloadElement)
                    downloadElement.click() //点击下载
                    document.body.removeChild(downloadElement) //下载完成移除元素
                    window.URL.revokeObjectURL(href) //释放掉blob对象
                } else {
                    this.$Message.error('下载失败')
                }
            }).catch(() => {
                this.$Message.error('下载失败')
            })
        },
        handlePreview(val) {
            this.showPreview = true;
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.$refs['viewForm'].resetFields();
                this.imgData = null
                this.getResumeTemplateById()
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    },
}
</script>
